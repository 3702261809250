import Jquery from '../../themes/vendor/jquery/js/jquery.js';
import talkExpert from './components/talk-expert.js';
import header from "./components/header.js";
header()
// import  '../vendor/TweenMax/tweenmax.js';
var TweenLite = require('../vendor/TweenMax/tweenmax.js');
//console.log('TweenLite :3', TweenLite);
// const jquery = new Jquery();
// jquery.jquery_import()

// // Will Remove this Code on Development 
// $(document).ready(function () {
//   $("[data-onload]").each(function () {
//     $(this).load($(this).data("onload"), function () { });
//   });
// })
// Will Remove this Code on Development 
// setTimeout(function(e) {

// var html = document.documentElement;
// var body = document.body;

// var scroller = {
//   target: document.querySelector("#scroll-container"),
//   ease: 0.09, // <= scroll speed
//   endY: 0,
//   y: 0,
//   resizeRequest: 1,
//   scrollRequest: 0,
// };

// var requestId = null;

// TweenLite.set(scroller.target, {
//   rotation: 0.01,
//   force3D: true
// });

// // window.addEventListener("load", onLoad);
// onLoad()
// function onLoad() {    
//   updateScroller();  
//   window.focus();
//   window.addEventListener("resize", onResize);
//   document.addEventListener("scroll", onScroll); 
// }

// function updateScroller() {
  
//   var resized = scroller.resizeRequest > 0;
    
//   if (resized) {    
//     var height = scroller.target.clientHeight;
//     body.style.height = height + "px";
//     scroller.resizeRequest = 0;
//   }
      
//   var scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0;

//   scroller.endY = scrollY;
//   scroller.y += (scrollY - scroller.y) * scroller.ease;

//   if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
//     scroller.y = scrollY;
//     scroller.scrollRequest = 0;
//   }
  
//   TweenLite.set(scroller.target, { 
//     y: -scroller.y 
//   });
  
//   requestId = scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
// }

// function onScroll() {
//   scroller.scrollRequest++;
//   if (!requestId) {
//     requestId = requestAnimationFrame(updateScroller);
//   }
//   // onResize()
// }

// function onResize() {
//   scroller.resizeRequest++;
//   if (!requestId) {
//     requestId = requestAnimationFrame(updateScroller);
//   }
// }
// },2000)

//!prevent entering e(exponent) value in input type number
export function preventDefaultInput() {
    const numberInput = document.querySelectorAll('input[type="number"]');
  
    numberInput?.forEach((input) => {
      input.addEventListener("keydown", (e) => {
        if (
          e.which === 38 ||
          e.which === 40 ||
          e.which === 13 ||
          ["e", "E", "+", "-"].includes(e.key)
        ) {
          // Arrow up, Arrow down, Enter
          e.preventDefault();
        }
      });
  
      //setting maxlength for the input type number
      input.addEventListener("input", function () {
        this.maxLength != -1 &&
          this.value.length > this.maxLength &&
          (this.value = this.value.slice(0, this.maxLength));
      });
    });
  }
  preventDefaultInput();