// popup("add-agent");
// Popup Trigger when the data Transfered

// Popup Element Show/Hide
const popup = (getPopupContent) => {
  // ----------------------------- this logic Need some Tweeks -----------------------------
  // document.addEventListener("click", function (evt) {
  //      popupElement = evt.target.getAttribute('data-popup');
  //      popupElementClose = evt.target.getAttribute('data-popup-close');
  // })
  const popupCheck = document.querySelector(
    `[data-popup-element=${getPopupContent}]`
  );

  if (popupCheck) {
    

    // Add Overlay Here
    // const popupOverlay = document.querySelector(".popup-overlay");
    // popupOverlay && popupOverlay.remove();
    let overlay = document.createElement("div");
    if (getPopupContent === "timeout") {
      overlay.style.zIndex = "1000";
      popupCheck.classList.add('popup-check')
    }
    overlay.className = "popup-overlay";
    // !(getPopupContent === 'insurer' || getPopupContent === 'sort-by' || getPopupContent === 'addons') && document.body.append(overlay)

    if (
      !(
        getPopupContent === "insurer" ||
        getPopupContent === "sort-by" ||
        getPopupContent === "addons"
      )
    ) {
      

      document.body.append(overlay);
    }
    // Add Remove Scroll from body when PoupShows
    document.body.classList.add("popupOverlay-scroll-remove");

    // show the popupElment
    document.body.style.overflow = "hidden";
    document
      .querySelector(`[data-popup-element="${getPopupContent}"]`)
      .setAttribute("shows", "true");
  } else {
  }
};
// Close Popup Using Function ---use this if needed
const closePopup = (getPopupContent) => {
  const popupCheck = document.querySelector(
    `[data-popup-element=${getPopupContent}]`
  );
  if (popupCheck) {
    // Remove Overlay
    document.querySelector(".popup-overlay")?.remove();
    // Remove Scroll from body when PoupClose
    document.body.classList.remove("popupOverlay-scroll-remove");
    document.body.style.overflow = "auto";

    // Hide the popupElment
    popupCheck.setAttribute("shows", "");
  }
};

const triggerPopup = () => {
  const popupElement = document.querySelectorAll("[data-popup]");
  const closePopupElement = document.querySelectorAll("[data-popup-close]");

  popupElement.forEach((element) => {
    element.addEventListener("click", function () {
      const getPopupData = this.getAttribute("data-popup");
      popup(getPopupData);
    });
  });
  

  closePopupElement.forEach((element) => {
    element.addEventListener("click", function () {
      const getPopupData = this.getAttribute("data-popup-close");
      closePopup(getPopupData);
    });
  });
};

export { triggerPopup, closePopup, popup };
