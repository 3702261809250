import {
  ajaxCall, ajaxUpload
}
  from "../utils";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import $ from "jquery";
import CryptoJS from 'crypto-js';
import moment from 'moment';
import module from '../../themes/js/pages/Home/fdcalculator'
import { closePopup, popup } from "../../themes/utils/modal/modal";
import { resendTimer } from "../../themes/js/components/header";
import { resetInput } from "../../themes/utils/otp/otp";
const manageScript = () => {

  $(document).ready(function () {

    //rewardpointval();
  
    function rewardpointval()
    {
      const reqparams = {
        InvAmount: ($("#fd_amount").val().replace(/[^0-9 ]/g,"")),
        Tenure: $("input[name='tenure']:checked").val()
      };
      let secKey = $("#res_sec").val();
      let ivKey  = $("#res_iv").val();

      let paramsval = encryptData(JSON.stringify(reqparams), secKey, ivKey);

      ajaxCall("/ajax/rewardCall", "POST", { datareq:paramsval}, (response) => {
        if (response.status === "success") {
          let RewP_val = response.data.GetCustomerRewardPointResult.RewardPoint;
          $("#reward-point").text(RewP_val);
        }else{
          $("#reward-point").text();
        }
      });
    }

    function encryptData(params, secretKey, ivValue){
      secretKey = CryptoJS.enc.Utf8.parse(secretKey);
      ivValue = CryptoJS.enc.Utf8.parse(ivValue);
      const encryptedData = CryptoJS.AES.encrypt(params, secretKey, {iv :ivValue });
   //   let finalParam = { enc_data : encryptedData.toString() };  
      return encryptedData.toString();  
    }
  
    function decryptData(encUrl, secretKey, ivValue){
      if(!encUrl) return { dec_data  :encUrl };
      const decryptedData = CryptoJS.AES.decrypt(encUrl, CryptoJS.enc.Utf8.parse(secretKey), {iv :CryptoJS.enc.Utf8.parse(ivValue) });
      let finalParam = { dec_data : decryptedData.toString(CryptoJS.enc.Utf8) };  
      return finalParam;  
    }  
    
    $("[data-name='name']").on("input", function (e) {
      this.value = this.value.replace(/[^A-Za-z\s.]/g, '');
    });

    $("[data-num='number']").on("input", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');
    });

    $.validator.addMethod("mobileStartingWith6789", function(value, element) {
      return this.optional(element) || /^[6789]/.test(value);
    }, "Mobile number must start with 6, 7, 8, or 9.");
    
    $.validator.addMethod("validEmailDomain", function(value, element) {
      return this.optional(element) || /\b[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+(?:com|in)\b/.test(value);
    }, "Please enter a valid email address.");

    

    $(document).on("click", "#citizen,#women,#form_reset,input[name='tenure']", function(){
      if($(this).attr("id")== "form_reset") {
        $("#tensure_month").html(60); 
        $("#tenure-8").prop("checked", true);
      }
      if($(this).attr("name")== "tenure") $("#tensure_month").html($("input[name='tenure']:checked").val()); 
      fdCalculation();
      rewardpointval();
    });
    $(document).on("change", "#payout,input[type='range']", function(){ 
      fdCalculation();
      rewardpointval();
    });

    $(document).on("focusout", "#fd_amount", function(){ 
      let thisAmnt = ($(this).val().replace(/[^0-9 ]/g,""));
      let thisVal =  Math.round(thisAmnt/ 1000) * 1000;
      $(this).val(formatNumber(thisVal));
      fdCalculation();
      rewardpointval();
    });


    $(document).on("click", "#explore_more_home", function(){ 
      $(".tabs-group__list").each(function(){  
          if($(this).attr("data-tab-active") == "true") { 
            window.location.href= '/'+$(this).attr("data-tab-name");
          }
      });
    });

    // will run when page gets loaded
    if ($('#home_page').val()=="Home" || $('#home_page').val()=="Fixed Deposit" || $('#home_page').val()=="Rewards"){
    fdCalculation();
    rewardpointval();
    }
    function fdCalculation(){ 
      let thisPayoutCheck = $("#payout")[0].tomselect ? $("#payout")[0].tomselect : null;
      if(!thisPayoutCheck) thisPayoutCheck = $("#payout").val();
      else thisPayoutCheck = thisPayoutCheck.getValue();
      let is_citizen = $("#citizen").is(":checked")? "Y":"N";
      let gender = $("#women").is(":checked")?"F":"A" ;
      let invest_amount = ($("#fd_amount").val().replace(/[^0-9 ]/g,""));
      let tensure = $("input[name='tenure']:checked").val();
      let payout = thisPayoutCheck == "on_maturity" ? "SDR" : "FDR";
      let newDate = new Date();
      let twoDigitMonth = ('0' + (newDate.getMonth() + 1)).slice(-2); 
      let twoDigitDate = ('0' + newDate.getDate()).slice(-2);      
      let reqData = {
        "objcalc": {
            "strComp": "SFL",
            "dblInvamt": invest_amount,
            "CertDate": newDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate ,
            "strTenure": tensure,
            "strscheme": is_citizen == "Y"?"S":"N",
            "strSCFlag": is_citizen,
            "strSubtype": "Normal",
            "strInvType": payout,
            "strGender": gender,
            "RequestForm" : "SKI"
        }
      };
      //console.log("raw req data: ", reqData);
      
        let secKey = $("#req_sec").val();
        let ivKey  = $("#req_iv").val();
        let secKey_res = $("#res_sec").val();
        let ivKey_res  = $("#res_iv").val();    
        let tokenSecKey = $("#token_sec").val();
        let tokenIv = $("#token_iv").val(); 
        let encdate = $("#encdate").val(); 
        const formattedDateTime = encdate;
        let tokenStr = "digi@"+formattedDateTime+"Z";
        let encData = encryptData(JSON.stringify(reqData), secKey, ivKey);
        let enc_header = encryptData(tokenStr, tokenSecKey, tokenIv);
        let paramData = {
          enc_header : enc_header, sendData : { "Request" : encData} 
        }


        ajaxCall("/ajax/commaonFdCalc", "POST",paramData, response => { 
        if (response.status == "success") {
            let responseTxt  = response.data.DigiCalculatorResult;
             // console.log("response data -->", responseTxt);
              
            let decDatasArr = responseTxt[0];  let decData;
            // to hide the payout div when on_maturity is selected
            if(payout == "SDR") {
              $("#payout_data").hide(); decData = decDatasArr.SDRCalc[0];
              decData.MaturityAmount = decData.MaturityAmount - decData.TotalInterestEarnings;
            } 
            else {
              $("#payout_data").show(); decDatasArr = decDatasArr.FDRCalc;
              decDatasArr.map((data, i) => {
                if(formatString(data.Frequency) == thisPayoutCheck) decData = data;
              });              
            } 
              // console.log("decData -->>", decData)
            $("#invested_amount").html(formatNumber(decData.MaturityAmount));
            $("#ttl_earnings").html(formatNumber(decData.TotalInterestEarnings));
            $("#interest_payout").html(formatNumber(decData.IntPayAmt));
            $("#interest_rate").html(`Interest Rate ${decData.W_ROI}%`);
            let profitAmnt = parseInt(decData.TotalInterestEarnings) ;
            let ttlAmount  = parseInt(decData.MaturityAmount)+ profitAmnt;
            $("#ttl_receivable").html(formatNumber(ttlAmount));
            let profitPercent = Math.round((profitAmnt*100) / ttlAmount);
            let basePercent = Math.round(( decData.MaturityAmount*100) / ttlAmount);
            if(payout == "FDR") {
              $("#interest_ratio_str").html(`${decData.Frequency} Interest Payout`);
              $("#effective_yield").remove();
            }
            else {
              if($("#effective_yield").length) {
                $("#effective_yield").html(`Effective Yield ${(decData.YieldRate).toFixed(2)}%`);
              } else
                $("#ratio_tab").append(`<p id="effective_yield"> Effective Yield ${(decData.YieldRate).toFixed(2)}% </p>`);
            } 

            $("#fdSubmit").html("Invest Now").removeAttr("disabled");
            const chartData={
              'earnings': profitPercent,
              'investAmount': basePercent
            }
            module.fdCalculator(chartData)
            $('[data-content="true"]').removeClass('no-data')
          $('[action-event="true"]').removeClass('show-err')

        } else {

          $("#invested_amount").html("-");
          $("#ttl_earnings").html("-");
          $("#interest_payout").html("-");
          $("#interest_rate").html(`Interest Rate - Nil`);
          $("#ttl_receivable").html("-");
          $("#fdSubmit").html("No Scheme Available").attr("disabled", "disabled");
          $('[data-content="true"]').addClass('no-data')
          const chartData={
            'earnings': 100,
            'investAmount':0
          }
          $('[action-event="true"]').addClass('show-err')
          module.fdCalculator(chartData)
            console.log("error data -->", response)
        }
      });        
    } 
    // to remove special chars and space in response data
    function formatString(str){
        return  str.toLowerCase().replace("-", "").replace(" ", "");
    }


    // to change the amount to indian number format  
    function formatNumber(num) {
      var n1, n2;
      num = num + '' || '';
      n1 = num.split('.');
      n2 = n1[1] || null;
      n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
      num = n2 ? n1 + '.' + n2 : n1;
      return num;
    }

    $('#supportform').validate({
      rules: {
        support_name: {
          required: true
        },
        support_email: {
          required: true,
          validEmailDomain: true
        },
        support_mobileno: {
          required: true,
          digits: true,
          mobileStartingWith6789: true,
          minlength: 10
        },
      },
      messages: {
        support_name: {
          required: "Please Enter Name."
        },
        support_email: {
          required: "Please Enter Email.",
          validEmailDomain: "Please enter a valid email address."
        },
        support_mobileno: {
          required: "Please Enter Mobile Number.",
          digits: "Please enter only Numbers.",
          mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9.",
          minlength:"Please enter 10 digits."
        }
      },
      errorElement: 'span',
      submitHandler: function () {
        const messageData = {
          name: $('#support_name').val(),
          email: $('#support_email').val(),
          mobileno: $('#support_mobileno').val(),
          page: $('#home_page').val(),
          is_agent: 0,
          desc: "",
          plan: ""
        }
        const params = {
          Reqdata: messageData,
        };
       
        ajaxCall("/ajax/commonCall", "POST", params, (response) => {
         // console.log('response :47', response);
          if (response.status === "success") {
            $('#supportform').parent().addClass('active');
            $ ("#supportform")[0].reset();
            setTimeout(function () {
              $('#supportform').parent().removeClass('active');
            }, 5000)
          }
        });
      }
    });

    $(document).on("change", "select[name='myselect']", function(){ 
      if ($(this).valid()) {
        $(this).next(".error").remove(); // Remove any existing error messages
      }
    });

    //Support plan form
    $('#supportPlanForm').validate({
      rules: {
        support_name: {
          required: true,
        },
        support_email: {
          required: true,
          validEmailDomain: true
        },
        myselect: {
          required: true,
        },
        support_mobileno: {
          required: true,
          digits: true,
          mobileStartingWith6789: true,
          minlength: 10
        },
      },
      messages: {
        support_name: {
          required: "Please Enter Name."
        },
        support_email: {
          required: "Please Enter Email.",
          validEmailDomain: "Please enter a valid email address."
        },
        myselect: {
          required: "Please Select Plan.",
        },
        support_mobileno: {
          required: "Please Enter Mobile Number.",
          digits: "Please enter only Numbers.",
          mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9.",
          minlength:"Please enter 10 digits."
        }
      },
      errorElement: 'span',
      submitHandler: function () {
        const messageData = {
          name: $('#support_name').val(),
          email: $('#support_email').val(),
          mobileno: $('#support_mobileno').val(),
          page: $('#home_page').val(),
          is_agent: 0,
          desc: "",
          plan: $('#claim-bonus').val()
        }
        const params = {
          Reqdata: messageData,
        };

        ajaxCall("/ajax/commonCall", "POST", params, (response) => {
          if (response.status === "success") {
            $('#supportPlanForm').parent().addClass('active');
            $("#supportPlanForm")[0].reset();
            setTimeout(function () {
              $('#supportPlanForm').parent().removeClass('active');
            }, 5000)
          }
        });
      }
    });
    
    //Join agent form
    $('#agentform').validate({
      rules: {
        agent_name: {
          required: true
        },
        agent_email: {
          required: true,
          validEmailDomain: true
        },
        agent_mobilno: {
          required: true,
          digits: true,
          mobileStartingWith6789: true,
          minlength: 10
        },
      },
      messages: {
        agent_name: {
          required: "Please Enter Name."
        },
        agent_email: {
          required: "Please Enter Email.",
          validEmailDomain: "Please enter a valid email address."
        },
        agent_mobilno: {
          required: "Please Enter Mobile Number.",
          digits: "Please enter only Numbers.",
          mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9.",
          minlength:"Please enter 10 digits."
        }
      },
      errorElement: 'span',
      submitHandler: function () {
        const messageData = {
          name: $('#agent_name').val(),
          email: $('#agent_email').val(),
          mobileno: $('#agent_mobilno').val(),
          page: $('#home_page').val(),
          is_agent: 1,
          desc: "",
          plan: ""
        }
        const params = {
          Reqdata: messageData,
        };

        ajaxCall("/ajax/commonCall", "POST", params, (response) => {
         //console.log('response :172', response);
          if (response.status === "success") {
            $('#agentform').parent().addClass('active');
            $ ("#agentform")[0].reset();
            setTimeout(function () {
              $('#agentform').parent().removeClass('active');
            }, 5000)
          }
        });
      }
    });
    //
    $('#contactForm').validate({
      rules: {
        contact_name: {
          required: true
        },
        contact_email: {
          required: true,
          validEmailDomain: true
        },
        contact_mobilno: {
          required: true,
          digits: true,
          mobileStartingWith6789: true,
          minlength: 10
        },
        contact_desc: {
          required: true,
          maxlength: 200          
        },
      },
      messages: {
        contact_name: {
          required: "Please Enter Name."
        },
        contact_email: {
          required: "Please Enter Email.",
          validEmailDomain: "Please enter a valid email address."
        },
        contact_mobilno: {
          required: "Please Enter Mobile Number.",
          digits: "Please enter only Numbers.",
          mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9.",
          minlength:"Please enter 10 digits."
        },
        contact_desc: {
          required: "Please Enter Description.",
          maxlength :  "Please enter no more than 200 characters."
        }
      },
      errorElement: 'span',
      submitHandler: function () {
        const messageData = {
          name: $('#contact_name').val(),
          email: $('#contact_email').val(),
          mobileno: $('#contact_mobilno').val(),
          desc: $('#contact_desc').val(),
          page: "support",
          is_agent: 0,
          plan: ""
        }
        const params = {
          Reqdata: messageData,
        };

        ajaxCall("/ajax/commonCall", "POST", params, (response) => {
         //'response :172', response);
          if (response.status === "success") {
            $('#contactForm').parent().addClass('active');
            $ ("#contactForm")[0].reset();
            setTimeout(function () {
              $('#contactForm').parent().removeClass('active');
            }, 5000)
          }
        });
      }
    });
   //send otp and resend otp
    $(document).on('click', "#verify-ph,#resend-otp", function (e) {
      e.preventDefault();
      $('#mobile_no').val($('#cus_mobileno').val());
      if($('#mobile_no').length < 10){
        resendTimer("stop");
      }
      $('#customerForm').validate({
        rules: {
          cus_mobileno: {
            required: true,
            minlength: 10,
            mobileStartingWith6789: true
          }
        },
        messages: {
          cus_mobileno: {
            required: "Please enter mobile number.",
            minlength: "Enter the valid number.",
            mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9."
          }
        },
        errorElement: 'span',
        errorClass: "error error--otp",
      });
      if ($("#customerForm").valid()) {
        const params = {
          MobileNo: $('#cus_mobileno').val() || $('#mobile_no').val(),
          send_otp: generateOtp(),
          OTPFlag: 1
        };
        ajaxCall("/ajax/sendOtpCall", "POST", params, (response) => {

          console.log("response-->",response)
          if (response.status === "success") {
            if (response.data.CustomerLoginDetResult.MessageType == "SUCCESS") {
              resendTimer("start")
              closePopup("ph-verification")
              popup("otp-verification")
            }
          } else {
            resendTimer("stop")
            $('#cus_mobileno,#otp-otp').next('span').remove();
            $('#cus_mobileno,#otp-otp').after(`<span class="error error--otp">${response.message}</span>`);
            setTimeout(function(){
              $('#cus_mobileno,#otp-otp').next('span').remove();
            },3000)
          }
        });
      }
    });

    $(document).on("input", "#mobile_no", function () {
      $('#cus_mobileno').val($('#mobile_no').val());
      if ($('#mobile_no').val().length < 10) {
        resendTimer("stop")
      }
    });

    $('#verifyOtpForm').validate({
      rules: {
        mobile_no: {
          required: true,
          minlength: 10,
          mobileStartingWith6789: true
        },
        otp_otp: {
          required: true,
          minlength: 4
        }
      },
      messages: {
        mobile_no: {
          required: "Please enter mobile number.",
          minlength: "Enter the valid number.",
          mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9."
        },
        otp_otp: {
          required: "Please enter OTP.",
          minlength: "Please enter a valid OTP.",
        }
      },
      errorElement: 'span',
      ignore: ".otp-container__input",
      errorClass: "error error--otp",
    });
    //edit mobile number
    $(document).on("focusout", "#mobile_no", function () {
      if ($("#mobile_no").valid()) {
        const params = {
          MobileNo: $('#cus_mobileno').val() || $('#mobile_no').val(),
          send_otp: generateOtp(),
          OTPFlag: 1
        };
        ajaxCall("/ajax/sendOtpCall", "POST", params, (response) => {
          if (response.status === "success") {
            resendTimer("start")
          } else {
            resendTimer("stop")
            $('#otp-otp').next('span').remove();
            $('#otp-otp').after(`<span class="error error--otp">${response.message}</span>`);
            setTimeout(function () {
              $('#otp-otp').next('span').remove();
            }, 3000)
          }
        });
      }
    });
    //verify otp 
    $(document).on("click", "#otpVerify", function (e) {
      e.preventDefault();
      $('#otp-otp').next('span').remove();
      if ($("#otp-otp").valid()) {
        const params = {
          MobileNo: $('#cus_mobileno').val() || $('#mobile_no').val(),
          otp: $('#otp-otp').val(),
          OTPFlag: 2
        };

        ajaxCall("/ajax/verifyOtpCall", "POST", params, (response) => {
          if (response.status === "success") {
            if (response.data.CustomerLoginDetResult.MessageType == "SUCCESS") {
              $('#cus_mobileno').val("")
              let mob = $('#cus_mobileno').val() || $('#mobile_no').val();
              let secKey = $("#res_sec").val();
              let ivKey = $("#res_iv").val();
              let enmobile = encryptData(mob, secKey, ivKey);
              sessionStorage.setItem('mobkey', enmobile);
              
              $('#otpVerify').addClass('active').text("OTP Verified Successfully");
              setTimeout(function () {
                closePopup("otp-verification")
                $('#otpVerify').removeClass('active').text("Verify");
                window.location.href = "rewards";
              }, 2000);
            }
          } else {
            $('#otp-otp').after(`<span class="error error--otp">${response.message}</span>`);
            setTimeout(function () {
              $('#otp-otp').next('span').remove();
            }, 3000)
          }
        });
      }
    });

    function generateOtp(length = 4) {
      const randomNum = "0123456789";
      let result = '';
      for (let i = 0; i < length; i++) {
        result += randomNum[Math.floor(Math.random() * randomNum.length)];
      }
      return result;
    }
    //submit Reedem
    $(document).on("click", "#subBankDet", function (e) {
      e.preventDefault();
      $("#bankDetForm").validate({
        rules: {
          AccHolderName: {
            required: true,
          },
          AccNumber: {
            required: true
          },
          reAccNum: {
            required: true,
            equalTo: "#AccNumber"
          },
          ifscCode: {
            required: true
          }
        },
        messages: {
          AccHolderName: {
            required: "Account holder name is required",
          },
          AccNumber: {
            required: "Account number is required"
          },
          reAccNum: {
            required: "Re-enter account number is required",
            equalTo: "Account number does not match"
          },
          ifscCode: {
            required: "IFSC code is required"
          }
        },
        errorElement: 'span'
      });
      if ($("#bankDetForm").valid()) {
        let redeemData = [];
        $('[name="cart1"]:checked').each(function () {
          redeemData.push({
            AckNo: $(this).parent().parent().find('#ackNum').text(),
            CertNo: $(this).parent().parent().find('#certNum').val()
          });
        });

        let secKey = $("#res_sec").val();
        let ivKey  = $("#res_iv").val();
        let checkVal = sessionStorage.getItem('mobkey');
     
        let decmobile = decryptData(checkVal, secKey, ivKey);
        
        const params = {
          RedeemDetail: {
            RedeemBankDet: {
              AccHolderName: $('#AccHolderName').val(),
              AccountNumber: $('#AccNumber').val(),
              BankName: $('#bankName').val(),
              BranchName: $('#branchName').val(),
              IFSCCode: $('#ifscCode').val(),
              MobileNo:decmobile.dec_data
            },
            RedeemRewardDet: redeemData
          }
        }
        ajaxCall("/ajax/redeemCall", "POST", params, (response) => {
          if (response.status === "success") {
            if (response.data.MessageType == "SUCCESS") {
              $('[name="cart1"]:checked').each(function () {
                $('#reward-points').text(0);
                $("#historyCard").parent().removeClass('no-records-found');
                $('#remcard_'+$(this).attr('index-id')).find(".redeem-card__checkbox").replaceWith('<p class="redeem-card__status" data-status="processing">processing</p>')
                $("#historyCard").prepend($('#remcard_'+$(this).attr('index-id')).clone());
                $('#remcard_'+$(this).attr('index-id')).remove()
              });
              if ($('#redeemCard').children().length == 1) {
                $("#redeemCard").parent().addClass('no-records-found')
              }
              $('#reward-container').parent().removeClass('js-active');   
              $('#grid-animation').removeClass('js-active')     
              $('#continue-btn').addClass('disabled');
              popup("sucess");
            } else {
              popup("failure")
            }
          }
        });
      }
    });

    $(document).on("input", "#ifscCode", function (e) {
      let ifscCode = $(this).val().toUpperCase().replace(/[^A-Za-z0-9]/g, '');
      let firstFour = ifscCode.slice(0, 4).replace(/[^A-Za-z]/g, '');
      let rest = ifscCode.slice(4).replace(/[^0-9]/g, '');
      $(this).val(firstFour + rest);
      if (ifscCode.length > 10) {
        const params = {
          ifsc_code: ifscCode
        };
        ajaxCall("/ajax/ifscCall", "POST", params, (response) => {
          if (response.status === "success") {
            let ifscCodeDet = response.data.IFSCCODEServiceResult
            if (ifscCodeDet.length > 0) {
              $("#bankName").val(ifscCodeDet[0].BnkDescr);
              $("#branchName").val(ifscCodeDet[0].BankBranch);
              $("#ifscCode").next('span').remove();
              $("#subBankDet").css("pointer-events", "auto");
            } else {
              $("#ifscCode").next('span').remove();
              $("#ifscCode").after(`<span class="error">Please Enter the valid IFSC Code</span>`)
              $("#subBankDet").css("pointer-events", "none");
            }
          }
        });
      } else {
        $("#ifscCode").next('span').remove();
        $("#bankName").val("");
        $("#branchName").val("");
      }
    });

    $(document).on("click", "#checkuser", function (e) {

      let checkVal = sessionStorage.getItem('mobkey');

      if(checkVal)
      {
        window.location.href = "rewards";
      }else{
        popup("ph-verification")
      }
          
    });

    // will run when page gets loaded
    if ($('#home_page').val() == "Rewards") {

      let secKey = $("#res_sec").val();
      let ivKey  = $("#res_iv").val();

      let moben = sessionStorage.getItem('mobkey');

      let decmobile = decryptData(moben, secKey, ivKey);
      const params = {
        MobileNo:decmobile.dec_data
      };
      ajaxCall("/ajax/rewardDetailCall", "POST", params, (response) => {
        if (response.status === "success") {
          let rewardDet = response.data.GetCustomerRewardDetailsResult
          if(rewardDet.CustomerRewardDet == null || rewardDet.MessageType == "Error") {
            $("#redeemCard,#historyCard").parent().addClass('no-records-found');
          } else {
            let cusBankDet = rewardDet.CustomerBankDet;
            let cusRewardDet = rewardDet.CustomerRewardDet;
            let sameName = cusRewardDet.every(name => name.CustomerName == cusRewardDet[0].CustomerName);
            sameName ? $('#customer-name').text(cusRewardDet[0].CustomerName) : $('#customer-name').text("Invester");
            if (cusBankDet.length > 0) {
              $('#AccHolderName').val(cusBankDet[0].AccHolderName);
              $('#AccNumber,#reAccNum').val(cusBankDet[0].AccountNumber);
              $('#ifscCode').val(cusBankDet[0].IFSCCode);
              $('#bankName').val(cusBankDet[0].BankName);
              $('#branchName').val(cusBankDet[0].BranchName);
            }
            const reedemFilter = cusRewardDet.filter(item => item.RwdReqStatus == "N" && item.RwdProcessStatus == "N");
            const historyFilter = cusRewardDet.filter(item => item.RwdReqStatus == "Y" && item.RwdProcessStatus == "Y" || item.RwdReqStatus == "Y" && item.RwdProcessStatus == "N");
            if(!reedemFilter.length){
              $("#redeemCard").parent().addClass('no-records-found');
            }
            if(!historyFilter.length){
              $("#historyCard").parent().addClass('no-records-found');
            }
            cusRewardDet.forEach((RewardData,index) => {
              if(RewardData.RwdProcessStatus == "N" && RewardData.RwdReqStatus == "N") {
                let rewardCard = `<div class="redeem-card border-hover" id = "remcard_${index}"><div class="redeem-card__deposit-amount">
                  <p class="redeem-card__deposit-amount__title">Deposit Amount</p>
                  <p class="redeem-card__deposit-amount__amount">${RewardData.Amount}/-</p>
                  </div>
                  <div class="checkbox-wrapper redeem-card__checkbox">
                      <input class="check-input redeem-card__checkbox" index-id=${index}  id="cart${index}" data-card-check="point-${index}" name="cart1" type="checkbox" />
                      <label class="checkbox-wrapper__label" for="cart${index}"></label>
                  </div>
                  <div class="redeem-card__tenure-content">
                      <p class="redeem-card__tenure-content__title">Tenure</p>
                      <p class="redeem-card__tenure-content__period">${RewardData.Tenure}</p>
                  </div>
                  <div class="redeem-card__date">
                      <p class="redeem-card__date__title">Deposit Date</p>
                      <p class="redeem-card__date__content">${RewardData.CertDate}</p>
                  </div>
                  <p class="redeem-card__name">${RewardData.CustomerName} </p>
                  <div class="redeem-card__reward-container">
                      <p class="redeem-card__reward-container__badge">Reward Point</p>
                      <p class="redeem-card__reward-container__amount" data-redeem-point="point-${index}">${RewardData.RewardAmnt}</p><img class="redeem-card__reward-container__img" alt="" src="../assets/images/svg/reward-card-img.svg" />
                  </div>
                  <p class="reward-points">Acknowledgment No :<span class="reward-points__point" id="ackNum">${RewardData.AckNo}</span></p><input type="hidden" id="certNum" value=${RewardData.CertNo}></div>`
                $("#redeemCard").append(rewardCard);
              } else {
                const status = (RewardData.RwdProcessStatus == "Y" && RewardData.RwdReqStatus == "Y") ? "completed" : "processing";
                let rewardCard = `<div class="redeem-card border-hover">
                  <div class="redeem-card__deposit-amount">
                    <p class="redeem-card__deposit-amount__title">Deposit Amount</p>
                    <p class="redeem-card__deposit-amount__amount">${RewardData.Amount}/-</p>
                  </div>
                  <p class="redeem-card__status" data-status=${status}>${status}</p>
                  <div class="redeem-card__tenure-content">
                    <p class="redeem-card__tenure-content__title">Tenure</p>
                    <p class="redeem-card__tenure-content__period">${RewardData.Tenure}</p>
                  </div>
                  <div class="redeem-card__date">
                    <p class="redeem-card__date__title">Deposit Date</p>
                    <p class="redeem-card__date__content">${RewardData.CertDate}</p>
                  </div>
                  <p class="redeem-card__name">${RewardData.CustomerName} </p>
                  <div class="redeem-card__reward-container">
                    <p class="redeem-card__reward-container__badge">Reward Point</p>
                    <p class="redeem-card__reward-container__amount" data-redeem-point="point-1">${RewardData.RewardAmnt}</p><img class="redeem-card__reward-container__img" alt="" src="../assets/images/svg/reward-card-img.svg"/>
                  </div>
                  <p class="reward-points">Acknowledgment No :<span class="reward-points__point">${RewardData.AckNo}</span></p>
                </div>`
                $("#historyCard").append(rewardCard);
              }
            });
          }
        }
      });
    }

  });
}



const initmanage = () => {
  manageScript();
};
export {
  initmanage
}
