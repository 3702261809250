import { triggerPopup } from "../../utils/modal/modal";
import { otpInput, resetInput } from "../../utils/otp/otp";

export const resendTimer = (operation) => {
  let countdown = 60; // Start with 30 seconds
  let resendElement = document.getElementById("resend-otp")
  if (window.intervalId) {
    clearInterval(intervalId);
  }
 if(operation==='start') {

  function timer() {
    if (countdown < 0) {
      clearInterval(window.intervalId);
      resendElement.textContent = `Resend OTP`;
      resendElement.classList.remove("otp-resended");
      return;
    }
    resendElement.textContent = `Resend OTP in ${countdown
      .toString()
      .padStart(2, "0")} secs`;
      resendElement.classList.add("otp-resended");
    countdown--;
  }
  timer()
  window.intervalId = setInterval(timer, 1000);
 }
 else {
  resendElement.textContent = `Resend OTP`;
  resendElement.classList.remove("otp-resended");
  // resendElement.style.pointerEvents = 'none'
 }
}

const header = () => {
  window.intervalId = null; // Variable to store the interval ID
  triggerPopup();
  function toggleMenu(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    if (e?.target.closest("[data-product-menu]")) {
      // console.log('menu clicked')
      if (
        e.target.hasAttribute("data-product-submenu") ||
        e.target.closest("[data-product-submenu]")
      ) {
        const activeLink = [
          ...document.querySelectorAll("[data-product-submenu]"),
        ].find((element) => element.classList.contains("js-active"));
        // console.log('active link', activeLink)
        activeLink && activeLink.classList.remove("js-active");
        e.target
          .closest("[data-product-submenu]")
          .classList.toggle("js-active");
        window.location.href = e.target
          .closest("[data-product-submenu]")
          .getAttribute("data-product-submenu");
      }
      let isActive = this.classList.toggle("js-submenu-visible");
      this.lastElementChild.style.height = isActive
        ? this.lastElementChild.scrollHeight + 10 + "px"
        : "0";
    } else {
      //console.log('this', this)
      let isActive = this.classList.toggle("js-mobile-bar-active");
      if (isActive) {
        document.body.style.overflowY = "hidden";
        // this.nextElementSibling.style.transform =  `translateY(60px)`
        this.nextElementSibling.style.height = window.innerHeight + "px";
        // this.nextElementSibling.style.opacity = "1"
        this.nextElementSibling.style.visibility = "visible";
        this.nextElementSibling.style.zIndex = "2";
        // this.nextElementSibling.style.overflow = "unset"
      } else {
        const nestedMenu = this.nextElementSibling.querySelector(
          "[data-product-menu]"
        );
        if (nestedMenu.classList.contains("js-submenu-visible")) {
          nestedMenu.classList.remove("js-submenu-visible");
          nestedMenu.lastElementChild.style.height = "0";
        }
        document.body.style.overflowY = "auto";
        // this.nextElementSibling.style.transform = `translateY(${-Math.abs(window.innerHeight)}px)`
        this.nextElementSibling.style.height = "0";
        //  this.nextElementSibling.style.opacity = "0"
        this.nextElementSibling.style.visibility = "hidden";
        // this.nextElementSibling.style.overflow = "hidden"
      }
    }
  }
  const productsMenu = document.querySelector("[data-product-menu]");
  const mobileMenu = document.querySelector("[data-mobile-menu]");
  //console.log(productsMenu);

  productsMenu?.addEventListener("click", toggleMenu);
  mobileMenu?.addEventListener("click", toggleMenu);

  window.addEventListener("click", function (e) {
    //console.log(e.target)
    if (!e.target.closest("[data-product-menu]")) {
      // console.log('inside if condition',e.target.closest('[data-product-menu]'))
      const submenu = document.querySelector("[data-product-menu]");
      submenu && submenu.classList.remove("js-submenu-visible");
      // console.log(submenu)
      submenu && (submenu.lastElementChild.style.height = "0")
    }
  });

  //

  var header = document.querySelector(".header");
  const loginBtn = [...document.querySelectorAll("[data-login]")];
  console.log("login_btn", loginBtn);
  var navbarHeight = header.offsetHeight;
  var lastScrollTop = 0;

  window.onscroll = function () {
    scrollHide();
  };

  function scrollHide() {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    //console.log(st);
    if (st > lastScrollTop) {
      header.classList.add("hide");
      loginBtn.forEach((element) => {
        element.classList.add("hide");
        element.classList.remove("show");
      });
    } else {
      header.classList.remove("hide");
      loginBtn.forEach((element) => {
        element.classList.remove("hide");
        element.classList.add("show");
      });
    }
    lastScrollTop = st <= 0 ? 0 : st;
  }

 

  const editIcon = document.getElementById("edit-otp");
  const resendOtp = document.getElementById("resend-otp");
  const mobileInput = document.getElementById('mobile_no')
  resendOtp?.addEventListener("click", function (e) {
    resendTimer("start")
    // let countdown = 30; // Start with 30 seconds
    // if (intervalId) {
    //   clearInterval(intervalId);
    // }

    // function timer() {
    //   if (countdown < 0) {
    //     clearInterval(intervalId);
    //     resendOtp.textContent = `Resend Otp`;
    //     resendOtp.classList.remove("otp-resended");
    //     return;
    //   }
    //   resendOtp.textContent = `Resend OTP in ${countdown
    //     .toString()
    //     .padStart(2, "0")} secs`;
    //   resendOtp.classList.add("otp-resended");
    //   countdown--;
    // }
    // timer()
    // intervalId = setInterval(timer, 1000);
  });
  document.addEventListener("DOMContentLoaded", function () {
    const verifyPh = document.getElementById("verify-ph");
    verifyPh?.addEventListener("click", (e) => {
      e.preventDefault();
      resetInput({
        count: { otp: 4 },
        className: "otp-container__input",
        isOtpFocus: true,
        isReset: true,
      })
      // otpInput({
      //   count: { otp: 4 },
      //   className: "otp-container__input",
      //   isOtpFocus: false,
      //   isReset: true,
      // });
    });
    editIcon?.addEventListener("click", (e) => {
      mobileInput.readOnly = false;
      mobileInput.focus();
      // Ensure the contenteditable field is focused

      const length = mobileInput.value.length;
      mobileInput.setSelectionRange(length, length);
      
    });
    loginBtn.forEach((element) => {
      element.classList.add("login-animation");
    });
    // loginBtn.classList.add("login-animation");
    setTimeout(() => {
      loginBtn.forEach((element) => {
        element.classList.remove("login-animation");
      });
    }, 3000);
  });

  mobileInput.addEventListener('focusout', function()
   {
    this.readOnly = true
    this.blur()
   }
  )

  // document.addEventListener("click", function (e) {
  //   if (!(e.target.id === "edit-otp")) {
  //     console.log("false", e.target);
  //     editIcon && (mobileInput.readOnly = true, mobileInput.focus)
  //   }
  // });
};

export default header;
