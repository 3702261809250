import { popup } from "../modal/modal";

const otpInput = (obj) => {
  //helper function to set multiple attribute to same element
  function setAttribute(element, attr, disable = "") {
    for (let key in attr) {
      element.setAttribute(key, attr[key]);
    }
    disable && (element.disabled = true);
  }

  // auto focus first otp input
  const otpFocus = (attr) => {
    if (attr) {
        const otpInput = document
          .querySelector("body")
          .querySelector(".otp-container input");
        setTimeout(function () {
          otpInput.disabled = false;
          otpInput.focus();
        }, 500);
      }
     else {
      const otpInput = document
        .querySelector("body")
        .querySelector(".otp-container input");
      setTimeout(function () {
        otpInput.disabled = false;
        otpInput.focus();
      }, 500);
    }
  };

  //reset otp input
  function resetPopup(e) {
    popup(e.target.getAttribute("data-popup"));
    const hasInput = document.querySelectorAll(".otp-container input");

    if (hasInput.length > 0) {
      hasInput.forEach((element) => {
        element.remove();
      });
    }

    otpInput(obj);
  }

  // Function to set up OTP input listeners
  function setupOtpInputListeners(container) {
    //creating input based on the count

    for (let i = 0; i < obj.count[container.id]; i++) {
      let inputEl = document.createElement("input");
      if (obj.type) {
        setAttribute(
          inputEl,
          {
            type: obj.type,
            pattern: "d",
            maxlength: "1",
            class: obj.className,
            id: obj.id[container.id],
            input: "numeric",
            "data-otp": "",
          },
          "disable"
        );
      } else {
        setAttribute(
          inputEl,
          {
            type: "number",
            pattern: "d",
            maxlength: "1",
            class: obj.className,
            input: "numeric",
            "data-otp": "",
          },
          "disable"
        );
      }
      container.append(inputEl);
    }
    const otpInput = document
      .querySelector("body")
      .querySelector(".otp-container input");
    setTimeout(function () {
      otpInput.disabled = false;
      otpInput.focus();
    }, 600);
    //check whether input is already present
    const isInput = document.getElementById(`otp-${container.id}`);
    isInput && isInput.remove();
    const otpElement = document.createElement("input");
    // type="text" id="verificationCode" placeholder="Enter verification code" readonly hidden
    setAttribute(otpElement, {
      type: "text",
      id: `otp-${container.id}`,
      placeholder: `Enter ${container.id} code`,
      readonly: "true",
      hidden: "true",
      name: `otp_${container.id}`,
    });
    container.insertAdjacentElement("afterend", otpElement);
    //* paste scenario
    container.addEventListener("paste", function (ev) {
      var targetInput = ev.target;
      var clip = ev.clipboardData.getData("text").trim();
      if (!/^\d{1,6}$/.test(clip)) {
        ev.preventDefault();
        return;
      }
      var characters = clip.split("");
      var inputs = Array.from(container.querySelectorAll(`.${obj.className}`));

      inputs.forEach(function (input, i) {
        input.value = characters[i] || "";
      });

      enableNextBox(inputs[0], 0);
      inputs[inputs.length - 1].removeAttribute("disabled");
      inputs[inputs.length - 1].focus();
      updateOTPValue(inputs, otpElement.id);
    });
    //* Input scenario
    container.addEventListener("input", function (ev) {
      var targetInput = ev.target;
      if (targetInput.value) {
        ev.preventDefault();
      }
      if (!/^\d$/.test(targetInput.value) && targetInput.value != "") {
        targetInput.value = "";
        return;
      }

      var currentIndex = Array.from(
        container.querySelectorAll(`.${obj.className}`)
      ).indexOf(targetInput);
      var nextIndex = currentIndex + 1;
      var nextInput = container.querySelector(
        `.${obj.className}:nth-child(${nextIndex + 1})`
      );

      if (targetInput.value && nextInput) {
        // targetInput.setAttribute('disabled','true')
        nextInput.disabled = false;
        nextInput.focus();
        targetInput.disabled = true;
      }
      targetInput.style.caretColor = "#000";
      if (!nextInput && targetInput.value) {
        targetInput.disabled = false;
        targetInput.style.caretColor = "transparent";
        targetInput.setAttribute("readonly", "true");
      }
      updateOTPValue(
        container.querySelectorAll(`.${obj.className}`),
        otpElement
      );
    });
    //* Keydown scenario
    container.addEventListener("keydown", function (ev) {
      var targetInput = ev.target;

      if (targetInput.value && ev.key === "Backspace") {
        targetInput.removeAttribute("readonly");
      }
      if (!targetInput.value && ev.key === "Backspace") {
        var currentIndex = Array.from(
          container.querySelectorAll(`.${obj.className}`)
        ).indexOf(targetInput);

        if (currentIndex > 0) {
          var prevInput = container.querySelector(
            `.${obj.className}:nth-child(${currentIndex})`
          );
          targetInput.disabled = true;
          prevInput.disabled = false;
          prevInput.focus();
        }
      }
    });

    //* click event for last input
    const otpInputs = [...document.querySelectorAll("[data-otp]")];
    console.log("opt", otpInputs);

    const lastOtpElement = otpInputs && otpInputs[otpInputs.length - 1];
    console.log("lastelement", lastOtpElement);

    lastOtpElement?.addEventListener("click", function () {
      console.log("clicked");

      if (this.hasAttribute("readonly")) {
        this.setAttribute("readonly", "false");
      }
    });

    // * other utility functions
    if (obj.isOtpFocus) {
      otpFocus(
        document
          .getElementById(`${Object.keys(obj.count)[0]}`)
          ?.closest("[data-popup-element]")
          ?.getAttribute("data-popup-element")
      );
    }

    if (obj.isReset) {
      document.addEventListener("click", function (e) {
        if (e.target.hasAttribute("data-resend")) {
          resetPopup(e);
          otpFocus();
        } else if (
          e.target.getAttribute("data-popup-close") &&
          e.target.closest('[data-popup-variant="otp"]')
        ) {
          resetPopup(e);
        }
      });
    }
  }

  // Get OTP input container
  var otpContainer = [...document.querySelectorAll(".otp-container")];
  

  otpContainer.length > 0 &&
    otpContainer.forEach((element) => setupOtpInputListeners(element));
  // if(otpContainer){
  // // Set up OTP input listeners for the container
  // setupOtpInputListeners(otpContainer, "verificationCode");
  // }

  // Function to enable the next box in the OTP sequence
  function enableNextBox(input, currentIndex) {
    var inputValue = input.value;
    if (inputValue === "") {
      return;
    }
    var nextIndex = currentIndex + 1;
    var nextBox = input.parentElement.querySelector(
      `.${obj.className}:nth-child(${nextIndex + 1})`
    );
    if (nextBox) {
      nextBox.removeAttribute("disabled");
    }
  }

  // Function to update OTP value
  function updateOTPValue(inputs, otpElement) {
    var otpValue = "";
    inputs.forEach(function (input) {
      otpValue += input.value;
    });
    otpElement.value = otpValue;
  }
};

const resetInput = (obj) => {
  const hasInput = document.querySelectorAll(".otp-container input");
  if (hasInput.length > 0) {
    hasInput.forEach((element) => {
      element.remove();
    });
  }
  otpInput(obj);
};
export { otpInput, resetInput };
